<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>
    <!-- Left Col -->
    <b-navbar-nav
      class="nav w-sm-100 d-sm-flex location-picker-nav"
      v-if="showLocationScopePicker"
    >
      <location-scope-picker />
    </b-navbar-nav>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <notification></notification>
      <dark-Toggler :style="colors" class="d-none d-lg-block mr-2" />

      <b-nav-item-dropdown
        v-if="userProfile != null"
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ accountScope.name }}
              <span v-if="accountScope.cvr != null">
                - {{ accountScope.cvr }}</span
              >
            </p>
            <span class="user-status">
              <span>{{ accountScope.address }} </span>
              <span> {{ userProfile.name }} </span>
            </span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            class="badge-minimal"
            :text="userProfile.initials"
          >
          </b-avatar>
        </template>
        <b-dropdown-group>
          <b-dropdown-item
            :link-class="'pt-0'"
            class="location-search pt-0"
            @click.native.capture.stop
          >
            <span class="d-flex align-items-center justify-content-between">
              <input
                type="text"
                class="form-control"
                :placeholder="T('Web.Generic.Search')"
                v-model="search"
              />
            </span>
          </b-dropdown-item>
        </b-dropdown-group>
        <template v-if="search != ''">
          <b-dropdown-group class="account-switcher">
            <div
              v-if="accountsList.length == 0"
              class="text-center font-weight-bold"
            >
              {{ T("Web.Generic.TableEmpty") }}
            </div>
            <b-dropdown-item
              v-else
              :class="{ active: account.id == currentAccontId }"
              v-for="account in accountsList"
              :key="account.id"
              @click="swapAccount(account)"
            >
              <span class="d-flex align-items-center justify-content-between">
                {{ account ? account.name : "" }}
                <feather-icon
                  v-if="account.id == currentAccontId"
                  icon="CheckIcon"
                />
              </span>
            </b-dropdown-item>
          </b-dropdown-group>
        </template>
        <template v-else-if="directoriesList.length > 0">
          <b-dropdown-group
            :header="T('Web.Generic.SwitchAccount', 'Switch account')"
            class="account-switcher"
          >
            <template v-for="directory in directoriesList">
              <b-dropdown-item
                :class="{ active: directory.id == currentAccontId }"
                :key="directory.id"
                @click="swapAccount(directory)"
                class="bg-light"
              >
                <span
                  class="d-flex align-items-center justify-content-between font-weight-bold"
                >
                  {{ directory.name }}
                  <span>
                    <feather-icon
                      icon="GlobeIcon"
                      v-b-tooltip.hover.right="'Directory'"
                    />
                    <feather-icon
                      class="ml-1"
                      v-if="directory.id == currentAccontId"
                      icon="CheckIcon"
                    />
                  </span>
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                :class="{ active: account.id == currentAccontId }"
                v-for="account in directoryConcerns(directory.id)"
                :key="account.id"
                @click="swapAccount(account)"
              >
                <span class="d-flex align-items-center justify-content-between">
                  {{ account ? account.name : "" }}
                  <feather-icon
                    v-if="account.id == currentAccontId"
                    icon="CheckIcon"
                  />
                </span>
              </b-dropdown-item>
            </template>
            <template v-if="externalAccounts.length > 0">
              <b-dropdown-item :disabled="true" class="bg-light">
                <span
                  class="d-flex align-items-center justify-content-between font-weight-bold"
                >
                  Eksterne konti
                  <span>
                    <feather-icon icon="ExternalLinkIcon" />
                  </span>
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                :class="{ active: account.id == currentAccontId }"
                v-for="account in externalAccounts"
                :key="account.id"
                @click="swapAccount(account)"
              >
                <span class="d-flex align-items-center justify-content-between">
                  {{ account ? account.name : "" }}
                  <feather-icon
                    v-if="account.id == currentAccontId"
                    icon="CheckIcon"
                  />
                </span>
              </b-dropdown-item>
            </template>
          </b-dropdown-group>
        </template>
        <template v-else>
          <b-dropdown-group
            v-if="accountsList.length > 1"
            id="dropdown-group-1"
            :header="T('Web.Generic.SwitchAccount', 'Switch account')"
          >
            <b-dropdown-item
              :class="{ active: account.id == currentAccontId }"
              v-for="account in accountsList"
              :key="account.id"
              @click="swapAccount(account)"
            >
              <span class="d-flex align-items-center justify-content-between">
                {{ account ? account.name : "" }}
                <feather-icon
                  class="ml-1"
                  v-if="account.id == currentAccontId"
                  icon="CheckIcon"
                />
              </span>
            </b-dropdown-item>
          </b-dropdown-group>
        </template>
        <b-dropdown-divider v-if="accountsList.length > 1"></b-dropdown-divider>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="executeLogOut"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>{{ T("Web.Generic.Logout", "Logout") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  BLink,
  BNavbarNav,
  BDropdown,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BDropdownGroup,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import { VBTooltip } from "bootstrap-vue";
import LocationScopePicker from "./LocationScopePicker.vue";
import Notification from "@/app/components/layout/vertical-nav/Notification.vue";
import ResourceService from "@/services/base/resource.service";
export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdown,
    BDropdownDivider,
    BAvatar,
    BDropdownGroup,
    // Navbar Components
    DarkToggler,
    LocationScopePicker,
    Notification,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      color: "#407e3d",
      concernService: null,
      search: "",
    };
  },
  computed: {
    ...mapGetters({
      userProfile: "appUser/getCurrentProfile",
      accountScope: "appUser/getCurrentAccountScope",
    }),
    accountsList() {
      let accounts = this.userProfile.accounts.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      if (this.search == "") return accounts;
      return accounts.filter(
        (a) => a.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      );
    },
    directoriesList() {
      return this.accountsList.filter((a) => a.type == "Directory");
    },
    currentAccontId() {
      return this.accountScope.id;
    },
    colors() {
      return {
        "--color": this.$store.getters["app/themeColor"]
          ? this.$store.getters["app/themeColor"]
          : this.color,
      };
    },
    showLocationScopePicker() {
      return this.$route.meta.showLocationScopePicker;
    },
    externalAccounts() {
      return this.accountsList.filter(
        (a) =>
          a.type != "Directory" &&
          !this.directoriesList.some((d) => d.id == a.directoryId)
      );
    },
  },
  methods: {
    ...mapActions({
      logOut: "appUser/logOut",
      switchAccountAsync: "appUser/switchAccountScopeAsync",
    }),
    async executeLogOut() {
      const service = new ResourceService("Auth/SSO");
      let LogoutUrl = (await service.get("logout-url")).data.data.LogoutUrl;
      this.logOut();
      if (LogoutUrl != "false") {
        window.location.replace(LogoutUrl);
      }
    },

    directoryConcerns(directoryId) {
      return this.accountsList.filter((a) => a.directoryId == directoryId);
    },
    async swapAccount(account) {
      await this.switchAccountAsync(account.id);
    },
  },
};
</script>
<style lang="scss">
.bookmark-wrapper a svg {
  color: var(--color) !important;
}
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
  width: unset;
  min-width: 300px;
}
#dropdown-group-1 li.active {
  background-color: rgba(64, 126, 61, 0.12);
  color: #407e3d;
}
.user-status {
  display: flex;
  flex-direction: column;
  align-items: end;
  > span {
    margin-bottom: 1px;
  }
}
.account-switcher {
  max-height: 300px;
  overflow-y: auto;
  .active .dropdown-item {
    background-color: rgba(64, 126, 61, 0.12);
    color: #407e3d;
  }
}
.dark-layout {
  .user-nav {
    color: #d0d2d6;
  }
}
.location-picker-nav {
  @media only screen and (max-width: 576px) {
    margin-left: auto !important;
  }
}
</style>